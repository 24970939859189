<template>
    <div class="aoi-panel1-form-wrapper">
        <div class="flex row start-panel" v-if="$route.path === '/manageaoi'">
            <div class="aoi-options-wrapper w35 flex-column">
               <div v-if="!$store.state.settings.isPreviewMode()" class="abs-item-row" v-on:click="handleOptionClick('create')">
                  <div class="title-icon-wrapper">
                      <b-icon-plus-circle></b-icon-plus-circle>
                  </div>
                  <div class="create-new-span">Create Workspace</div>
              </div>
              <div v-if="!$store.state.settings.isPreviewMode()" class="flex-row abs-item-subtitle">
                Process and Analyze a New Video
              </div>
              <div :data-cy="divManageExistingWorkspacesCy" v-if="!$store.state.settings.isPreviewMode()" class="abs-item-row" v-on:click="handleOptionClick('manage')">
                <div :data-cy="manageExistingWorkspacesCy" class="title-icon-wrapper">
                    <b-icon-pencil-square></b-icon-pencil-square></div>
                  <div class="create-new-span">Manage Existing Workspaces</div>
              </div>
              <div v-if="!$store.state.settings.isPreviewMode()" class="flex-row abs-item-subtitle" >
                Analyze an Existing Video with New Settings
              </div>
              <div class="abs-item-row" v-on:click="handleOptionClick('load')">
                <div class="title-icon-wrapper"><b-icon-play-fill></b-icon-play-fill></div>
                  <div class="create-new-span">Load Sample Workspace</div>
              </div>
              <div class="flex-row abs-item-subtitle">
                View a Sample Video Analysis
              </div>
            </div>
            <div class="w55">
                <div>NASH Introduction</div>
                <div class="flex row nowrap">
                    <div style="margin-top: 5px;">
                        <video ref="intro_video" src="@/assets/video/Intro.mp4"
                               controls width="100%" style="max-height: 400px"
                               poster="@/assets/images/nash_intro_poster.png"
                               disablePictureInPicture></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Constants from "@/helpers/constants"

export default {
    name: "startPanel",
    data() {
      return {
          playerSource: '',

          divManageExistingWorkspacesCy: Constants.DIV_MANAGE_EXISTING_WORKSPACES_CY,
          manageExistingWorkspacesCy: Constants.MANAGE_EXISTING_WORKSPACES_CY
      }
    },
    computed: {
        names() {
            return this.$store.state.pipelineConfigs.data.pipelineConfigs.map(a => a.name)
        }
    },
    methods: {
        stopIntroVideo() {
          this.$refs.intro_video.pause();
        },
        handleOptionClick(option) {
            this.stopIntroVideo();
            switch(option) {
                case 'create':
                    this.$router.push("/manageaoi/pipeline/new");
                    break;
                case 'manage':
                    this.$router.push("/manageaoi/pipelines");
                    break;
                case 'load':
                    this.$router.push("/manageaoi/pipelines");
                    break;
            }
            this.$emit("onOptionSelect", option);
        }
    }
}

</script>
<style lang="scss">
.aoi-panel1-form-wrapper {
    margin: 0;

    .start-panel{
        flex-wrap: nowrap;
        color: #fff;
        .aoi-options-wrapper{
            min-width: 250px;
            .abs-item-row {
                padding: 1px 10px 40px 10px !important;
                height: 30px;
                align-items: flex-start !important;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                margin: 0;
                .title-icon-wrapper {
                    margin-right:10px;
                    padding-top: 2px;
                    > svg {
                        font-size: 16px;
                    }
                }
            }
            .abs-item-subtitle{
                padding-left: 37px;
                font-size: 12px;
                margin-top: -15px;
                margin-bottom: 20px;
            }
            .abs-item-row:hover {
                color: #3a76b1;
                cursor: pointer;
            }
        }
    }

    .subtitle-text{
        padding-bottom: 10px;
        width: 300px;
        color: #ced6e0;
        font-size: 14px;
    }

    .w35 {
        width: 35%;
        height: 440px;
    }

    .w55 {
        width: 55%;
        height: 440px;
    }
}

</style>
